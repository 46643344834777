import strRandom from '../utils/strRandom';

export const createCouponWoo = async (points) => {

    const WooCommerceRestApi = require("@woocommerce/woocommerce-rest-api").default;
    const api = new WooCommerceRestApi({
        url: 'https://shopalpha.fr/',
        consumerKey: 'ck_7bf64eac0a7a13c96206cab56ae2640090b71453',//process.env.REACT_APP_WOO_KEY,
        consumerSecret: 'cs_d06fd984af8484b18a98da1f2c2e4b02ba60eca9', //process.env.REACT_APP_WOO_SECRET,
        version: "wc/v3"
    });

    const stringRandomCode = strRandom({
        includeUpperCase: true,
        includeNumbers: true,
        length: 5,
        startsWithLowerCase: true,
    });

    // Create a coupon
    const dataSend = {
        code: `Ambassadeur-${points}-${stringRandomCode}`,
        discount_type: "fixed_cart",
        amount: points.toString(),
        minimum_amount: points.toString(),
        usage_limit: 1,
        individual_use: false,
        excluded_product_categories: [],
        product_ids: [],
        excluded_product_ids: [],
        usage_limit_per_user: null,
        limit_usage_to_x_items: null,
        free_shipping: false,
        product_categories: [],
        exclude_sale_items: false,
    };

    const res = api.post("coupons", dataSend)
        .then((response) => {
            // Successful request
            console.log('response:', response)
            return response.data.code

        })
        .catch((error) => {
            // Invalid request, for 4xx and 5xx statuses
            return ("Response Status Error:", error.status);
        })
        .finally(() => {
            // Always executed.
        });

    return res

}
