//transformation de la date unix vers date fr
export const transfoDate = (string) => {
    let res
    if (string === null) {
        res = new Date()
    } else {
        res = new Date(parseInt(string))
    }

    return {
        date: res,
        string: res.toLocaleString(`fr-FR`, { year: 'numeric', month: '2-digit', day: '2-digit' })
    }
}

//transformation de la date string stripe vers date fr
export const transfoDateStripe = (string) => {
    let tabDate = string.split("-")
    let res = new Date(tabDate[0], tabDate[1], tabDate[2])
    return {
        date: res,
        string: res.toLocaleString(`fr-FR`, { year: 'numeric', month: '2-digit', day: '2-digit' })
    }
}