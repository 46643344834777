import axios from 'axios';
import { urlBdd } from './urlBdd'

export const updateAlphaPoints = async (data) => {

  //update points
  const alphaPoints = await axios({
    method: 'post',
    url: `${urlBdd}/graphQl`,

    data: {
      query: `
      mutation updateCustomer (
        $id: Int!, 
        $alpha_point: Int){
            
      updateCustomer(
        id:$id,
        alpha_point: $alpha_point ){
        id
      }
    }`,
      variables: {
        "id": parseInt(data.id, 10),
        "alpha_point": parseInt(data.balance, 10)
      }
    }

  })

  if (alphaPoints) {
    return alphaPoints.data.data.updateCustomer
  } else {
    return null
  }

}