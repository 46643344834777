
import React, { useState } from 'react';
import Moment from 'moment';
import gifts from '../../data/gifts';

import { Message } from 'primereact/message';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';

import './style.scss'
import ModalGift from '../ModalGift';

const Gifts = (props) => {

    const convertDate = (date) => {
        return Moment(date * 1000).format('DD/MM/YYYY');
    }

    const switchStatus = (status) => {
        let isActiv, msg;
        switch (status) {
            case 'incomplete':
                isActiv = false;
                msg = 'Incomplet'
                break
            case 'incomplete_expired':
                isActiv = false
                msg = 'Incomplet expiré'
                break
            case 'trialing':
                isActiv = true
                msg = `période d'essai`
                break
            case 'active':
                isActiv = true
                msg = "En Cours"
                break
            case 'past_due':
                isActiv = true
                msg = 'past_due'
                break
            case 'canceled':
                isActiv = false
                msg = 'Arrêté'
                break
            case 'unpaid':
                isActiv = false
                msg = 'Non payé'
                break
            default:
        }
        return { isActiv: isActiv, msg: msg }
    }

    //recup des cadeau disponible avec le solde de points
    const verifGifts = () => {
        let temp = null
        for (let item in gifts) {
            //recup l'écart de point (négatif si manque)
            const gap = props.user.user.alpha_point - gifts[`${item}`].nbPoints

            //verif si cadeau dispo
            let status = true
            let msg = ""
            if (gap < 0) {
                status = false
                msg = "Solde de points Insufisant"
            } else if (props.user.stripeBalance !== "null" && props.user.stripeBalance !== 0) {
                status = false
                msg = "Reduction Déja Appliqué sur la prochaine facture"
            }
            //ajout du gap et du disable ou enable
            const tempGift = {
                ...gifts[`${item}`],
                gap: gap,
                enable: status,
                msgStatus: msg
            }
            temp = {
                ...temp,
                [`${item}`]: tempGift
            }
        }
        return temp
    }
    const giftsVerified = verifGifts()

    //action au click sur les boutons de cadeaux - affichage de la modal
    const [modal, setModal] = useState({
        display: false,
        title: null,
        render: null,
    });
    const clickGift = (gift) => {
        setModal({
            display: true,
            title: gift.title,
            render: <ModalGift id={props.user.user.id} solde={props.user.user.alpha_point} gift={gift} setRefresh={props.setRefresh} />
        })
    }



    return (

        <div className={` lg:ml-2 mb-2 block ${props.className}`}>

            <div className="text-900 p-2">

                <div>
                    Solde d'Alpha-Points : {props.user.user.alpha_point}
                </div>

                <Accordion className="mt-1">
                    <AccordionTab header="Points générées par les affiliés:">
                        {
                            props.user.coupons.length > 0 ?

                                props.user.coupons.map((coupon) =>
                                    <div key={`${coupon.idCoupon}`} className="next">
                                        <div key={`${coupon.idCoupon}${coupon.reduc}`}>
                                            <Tag className="text-xl m-2" severity="info" value={coupon.reduc}></Tag>
                                            <span className="font-bold"> Date:</span> {coupon.date.string}
                                            <span className="font-bold"> Généré par:</span> {coupon.affiliated}
                                        </div>
                                    </div>
                                )
                                :
                                <Message className="mt-2" severity="info" text="Aucun bon de réduction généré" />
                        }
                    </AccordionTab>
                </Accordion>

                <div className="mt-2">Tes abbonnements Alphabody:</div>
                {
                    !!props.user.subscriptions ?

                        props.user.subscriptions.map((item) =>

                            <div key={`${item.id}`} className={`mb-1 mt-1 ${switchStatus(item.status).isActiv ? 'actif' : 'inactif'}`}>
                                <div key={`${item.id}-divStatus`}>
                                    <span className="font-bold">Statut:</span> {switchStatus(item.status).isActiv} {switchStatus(item.status).msg}
                                </div>
                                <div key={`${item.id}-divPeriode`}>
                                    <span className="font-bold">Période :</span> du {convertDate(item.created)} {!!item.canceled_at ? `au ${convertDate(item.canceled_at)}` : "à aujourd'hui"}
                                </div>
                                <div key={`${item.id}-divAmount`}>
                                    <span className="font-bold">Montant initial:</span> {item.items.data[0].plan.amount / 100} €
                                </div>
                            </div>

                        )
                        :
                        <div>
                            <Message className="mt-2" severity="info" text="Aucun abbonnement trouvé" />
                        </div>
                }
                <div className="mt-2">Reductions sur la prochaine facture:</div>
                {
                    props.user.stripeBalance !== "null" && props.user.stripeBalance !== 0 ?
                        <div>
                            <Message className="mt-2" severity="success" text={`${props.user.stripeBalance} €`} />
                        </div>
                        :
                        <div>
                            <Message className="mt-2" severity="info" text="Aucune reduction appliquée sur la prochaine facture" />
                        </div>
                }

                {!!giftsVerified &&
                    <div>
                        <div className="mt-2">Avantages :</div>
                        {
                            Object.keys(giftsVerified).map((gift) =>
                                <div key={`${giftsVerified[`${gift}`].title}-div`}>
                                    <Button
                                        className={`m-1 ${!giftsVerified[`${gift}`].enable && "p-button-secondary"}`}
                                        badgeClassName={`${!giftsVerified[`${gift}`].enable && "p-badge-danger"}`}
                                        key={giftsVerified[`${gift}`].title}
                                        type="button"
                                        label={giftsVerified[`${gift}`].title}
                                        badge={`${giftsVerified[`${gift}`].nbPoints} Points`}
                                        onClick={(e) => clickGift(giftsVerified[`${gift}`])}
                                    />
                                </div>
                            )
                        }
                    </div>
                }
            </div>
            <Dialog header={modal.title} visible={modal.display} style={{ width: '70vw' }} onHide={() => setModal({ display: false })}>
                {modal.render}
            </Dialog>
        </div >
    )
}

export default Gifts;