
import React from 'react';

const AmbassadorInfos = (props) => {

    const transfoDate = (string) => {
        let res = new Date(parseInt(string)).toLocaleString(`fr-FR`, { year: 'numeric', month: '2-digit', day: '2-digit' })
        return res
    }

    return (

        <div className={` mb-2 block ${props.className}`}>
            <div className="text-900 p-2">

                <div>
                    <span className="font-bold">Nom:</span> {props.ambassador.lastname}
                </div>
                <div>
                    <span className="font-bold">Prénom:</span> {props.ambassador.firstname}
                </div>
                <div>
                    <span className="font-bold">Programme:</span> {props.ambassador.product_id.name} {!!props.ambassador.sale && `(${props.ambassador.sale.type})`}
                </div>
                <div>
                    <span className="font-bold">Inscrit depuis :</span> {transfoDate(props.ambassador.created_at)}
                </div>
                <div>
                    <span className="font-bold">Prochaine facture le :</span> {!!props.ambassador.sale && transfoDate(props.ambassador.sale.next_term)}
                </div>
            </div>
        </div >
    )

}

export default AmbassadorInfos;