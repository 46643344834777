// Fonction pour envoyer une requete a l'API (recuperer les subscriptions du client avec l'email)

import { urlBdd } from './urlBdd'

export const getStripeBalance = async (id) => {

    const url = `${urlBdd}/customer/balance/stripe?cus_id=${String(id)}`

    const init = { method: 'GET' }
    const response = await fetch(url, init).then((response) => {
        if (!response.ok) {
            throw new Error("HTTP status " + response.status);
        }
        return response.balance;
    })
    return response
}