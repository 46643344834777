import { transfoDate, transfoDateStripe } from './transfoDate.js'
import dynamicSort from './dynamicSort.js'

export const calcCoupons = (props) => {

    //affichage de la reduction temporairement
    let reduc = "50 Point"

    //calcul des infos ambassadeur
    const nextAmbassadorBill = transfoDate(`${!!props.user.sale ? props.user.sale.next_term : new Date().getTime()}`)

    const calcPreviousAmbassadorBill = (type) => {
        let dateNextBill = {
            day: nextAmbassadorBill.date.getDate(),
            month: nextAmbassadorBill.date.getMonth(),
            year: nextAmbassadorBill.date.getFullYear(),
        }

        let sub, res
        switch (type) {
            case "mensuel":
                sub = 1
                break;
            case "trimestriel":
                sub = 3
                break;
            case "annuel":
                sub = 12
                break;
            default:
                sub = 1
                break;
        }
        res = new Date(dateNextBill.year, dateNextBill.month - sub, dateNextBill.day)
        return {
            date: res,
            string: res.toLocaleString(`fr-FR`, { year: 'numeric', month: '2-digit', day: '2-digit' })
        }
    }
    const previousAmbassadorBill = calcPreviousAmbassadorBill(`${!!props.user.sale ? props.user.sale.type : "mensuel"}`)

    // tri des detail strip de l'affilié pour récupérer les facture payées

    //objet coupons par affiliés
    let generatedCoupons = []
    if (!!props.user.affiliated) {
        for (let child of props.user.affiliated) {
            let id = 1
            if (!!child.sale) {
                for (let item in child.sale.detail) {
                    if (child.sale.detail[`${item}`] === "paid") {
                        const date = transfoDateStripe(item.slice(0, 10))
                        generatedCoupons.push({
                            idaffiliate: child.id,
                            affiliated: `${child.firstname} ${child.lastname}`,
                            statusBill: "paid",
                            date: date,
                            status: `${(date.date < nextAmbassadorBill.date && date.date > previousAmbassadorBill.date) ? "next" : "past"}`,
                            idCoupon: id,
                            reduc: reduc
                        })
                        id = id + 1
                    }
                }
            }
        }
    }

    generatedCoupons.sort(dynamicSort("idCoupon"));
    return generatedCoupons
}