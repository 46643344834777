
import React, { useState } from 'react';

import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { createCouponWoo } from '../../callApi/createCouponWoo'
import { updateStripeBalance } from '../../callApi/updateStripeBalance'
import { updateAlphaPoints } from '../../callApi/updateAlphaPoints'

const ModalGift = (props) => {

    const [coupon, setCoupon] = useState(null)
    const [balance, setBalance] = useState(false)
    const [copy, setCopy] = useState(false)
    const [button, setButton] = useState(true)
    const [spinner, setSpinner] = useState(false)

    const click = async () => {
        setButton(false)
        setSpinner(true)
        const diff = props.solde - props.gift.nbPoints

        switch (props.gift.type) {

            case "reducWoo":
                const coupon = await createCouponWoo(props.gift.nbPoints)
                setCoupon(coupon)
                break;

            case "reducAbbo":
                const balance = await updateStripeBalance(
                    {
                        amount: props.gift.nbPoints,
                        id: "cus_JggMkhqVroyUiM"
                    })
                setBalance(balance.ok)
                break;

            default:
                break;
        }
        updateAlphaPoints({
            id: props.id,
            balance: diff
        })
        props.setRefresh(true)
        setSpinner(false)
    }

    return (
        <div>
            {
                props.gift.enable ?
                    <div className="mt-2 flex justify-content-center">
                        {
                            button &&
                            <div>
                                <div className="mt-2">
                                    <Message severity="warn" text={props.gift.msgConfirm} />
                                </div>
                                <div className="mt-2">
                                    <Button onClick={() => click()}>Je Confirme</Button>
                                </div>
                            </div>
                        }
                        {
                            spinner &&
                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        }
                    </div>
                    :
                    <Message severity="error" text={props.gift.msgStatus} />
            }
            {
                coupon &&
                <div className="mt-2">
                    <p>Voici ton bon de réduction sur la boutique shopAlpha.fr fait en bon usage</p>
                    <p>N'oublie pas de le copier une fois cette fenetre fermé il ne te sera plus accessible</p>

                    <h4 className="select-all m-2 overflow-hidden text-overflow-ellipsis">{coupon}</h4>
                    <CopyToClipboard text={coupon}
                        onCopy={() => setCopy(true)}
                    >
                        <Button label="Copier le bon de réduction" icon={copy && "pi pi-check"} />
                    </CopyToClipboard>
                </div>
            }
            {
                balance &&
                <div className="mt-2">
                    <Message severity="success" text="La réduction a été appliquée avec succes" />
                </div>
            }
        </div>
    )

}

export default ModalGift;