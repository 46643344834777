
import React from 'react';
import { Tag } from 'primereact/tag';
import { Message } from 'primereact/message';

import { transfoDate } from '../../utils/transfoDate';

import './style.scss'

const AffiliatedInfos = (props) => {

    return (
        <div>

            {
                props.affiliation === null || props.affiliation === undefined
                    ?
                    <Message className="mt-2" severity="info" text="Aucun Filleul" />
                    :
                    <div className="mt-2 ">
                        <div className="text-900 p-2">

                            <div>
                                {
                                    props.affiliation.map((affiliate) =>

                                        <div key={`div-${affiliate.id}`} className={`mb-1 mt-1 ${affiliate.activ ? 'actif' : 'inactif'}`}>

                                            <div key={`div-${affiliate.id}-status`} className="text-xl">
                                                <span className="font-bold">{affiliate.firstname} {affiliate.lastname}</span>
                                                {affiliate.activ ?
                                                    <Tag key={`div-${affiliate.id}tag1`} className="m-1 text-base" severity="success" value="Actif" />
                                                    :
                                                    <Tag key={`div-${affiliate.id}tag2`} className="m-1 text-base" severity="danger" value="Inactif" />
                                                }
                                            </div>

                                            <div key={`div-${affiliate.id}-prog`}>
                                                <span className="font-bold">Programme :</span> {affiliate.product_id.name} {!!affiliate.sale && `(${affiliate.sale.type})`}
                                            </div >

                                            <div key={`div-${affiliate.id}-date`}>
                                                <span className="font-bold">Inscrit depuis :</span> {transfoDate(affiliate.created_at).string}
                                            </div>

                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div >
            }
        </div>
    )

}

export default AffiliatedInfos;