
import React, { useEffect, useState, useRef } from 'react';

import { getStripeBalance } from '../callApi/getStripeBalance';
import { sortStripeSubscription } from '../utils/sortStripeSubscription';

import { getCustomerWithAffiliation } from '../callApi/getCustomerWithAffiliation';
import { calcCoupons } from '../utils/calcCoupons';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';

import AmbassadorInfos from '../components/AmbassadorInfos'
import Gifts from '../components/Gifts';
import Link from '../components/Link';
import AffiliatedInfos from '../components/AffiliatedInfos';
import ModalLogin from '../components/ModalLogin';

const App = () => {

    //recup des params de l'url
    const params = new URLSearchParams(window.location.search)

    //definition des states
    //lié a cette page
    const [state, setState] = useState({
        activeIndex: 0,
        modalLogin: false,
    })
    //lié a l'utilisateur connecté
    const [user, setUser] = useState({
        login: false,
        email: params.get('email'),
        user: null,
        coupons: null,
        stripeBalance: 0,
        subscriptions: null
    })
    //pour le refresh
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        checkUser(user.email)
    }, [refresh])

    //recup du user a l'arrivé sur la page
    const checkUser = async (mail) => {
        let res = await getCustomerWithAffiliation(mail)

        if (!!res) {
            setUser({
                ...user,
                email: mail,
                user: res,
                login: true
            })
        }
    }
    useEffect(() => {
        checkUser(params.get('email'))
    }, [])

    //fermeture de la modal login si user trouvé
    const getInfos = async () => {
        //recup des infos utiles
        setUser({
            ...user,
            coupons: calcCoupons(user),
            stripeBalance: `${user.sale ? await getStripeBalance(user.sale.processor_cus_id) : null}`,
            subscriptions: await sortStripeSubscription(user)
        })
    }
    useEffect(() => {
        if (user.login) {
            setState({
                activeIndex: 0,
                modalLogin: false,
            })
            getInfos()
        }
    }, [user.login])

    //ouverture ou fermeture de la modal login
    const showModalLogin = () => {
        setState({ ...state, modalLogin: true });
    }
    const hideModalLogin = () => {
        setState({ ...state, modalLogin: false });
    }

    //elements du top menu
    const menu = useRef(null);
    const items = [
        {
            label: 'Ambassadeur', icon: 'pi pi-user', disable: true, command: () => {
                setState({ ...state, activeIndex: 0 })
            }
        },
        {
            label: 'Affiliés', icon: 'pi pi-users', disable: true, command: () => {
                setState({ ...state, activeIndex: 1 })
            }
        },
        {
            label: 'Avantages', icon: 'pi pi-wallet', disable: true, command: () => {
                setState({ ...state, activeIndex: 2 })
            }
        },
        {
            label: 'Lien de parrainnage', icon: 'pi pi-external-link', disable: true, command: () => {
                setState({ ...state, activeIndex: 3 })
            }
        },
    ];

    //element rendu en fonction du menu selectionné
    const returnPage = () => {
        switch (state.activeIndex) {
            case 0:
                return <AmbassadorInfos ambassador={user.user} />
            case 1:
                return <AffiliatedInfos coupons={user.coupons} affiliation={user.user.affiliated} />
            case 2:
                return <Gifts user={user} setRefresh={setRefresh} />
            case 3:
                return <Link ambassador_id={user.user.id} />

            default:
                break;
        }
    }

    return (

        <div className="block align-items-center justify-content-center m-2">
            <div className="surface-card p-3 shadow-2 border-round w-full lg:w-10 m-auto">

                <div className="flex justify-content-start">
                    <img src="Logo-Alphabody-Black.png" alt="Logo Alphabody" height={50} className="m-2" />
                </div>

                <div className="flex justify-content-end">

                    {
                        !user.login ?
                            <Button icon="pi pi-sign-in" label={`Connection`} className="m-2" onClick={() => showModalLogin()} />
                            :
                            <Button icon="pi pi-sign-out" label={`déconnection`} className="m-2" onClick={() => setUser({ ...user, user: null, login: false })} />
                    }

                </div>
                {
                    user.login &&
                    <React.Fragment>
                        <div className="card">

                            <Menu model={items} popup ref={menu} id="popup_menu" />
                            <Button label="Menu" className="sm:block md:hidden" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />

                            <TabMenu className="hidden md:block" activeIndex={state.activeIndex} model={items} />
                        </div>

                        <div>
                            {returnPage()}
                        </div>
                    </React.Fragment>
                }

                <Dialog header="Connection" visible={state.modalLogin} onHide={() => hideModalLogin()} breakpoints={{ '960px': '90vw' }} style={{ width: '80vw' }}>
                    <ModalLogin email={state.email} setUser={setUser} />
                </Dialog>

            </div>
        </div>

    )

}

export default App;