
import React, { useState } from 'react';

import { getCustomerWithAffiliation } from '../../callApi/getCustomerWithAffiliation';
import verifMail from '../../utils/verifMail'

import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const ModalLogin = (props) => {

    const [error, setError] = useState('')
    const [email, setMail] = useState(null)
    const [goodMail, setGoodMail] = useState(null)

    async function changeEmail(value) {
        setError('')
        //verif regex mail
        setMail(value)
        if (verifMail(value)) {
            setGoodMail(true)
            setMail(value)
        } else {
            setGoodMail(false)
        }
    }

    async function checkUser() {

        let res = await getCustomerWithAffiliation(email)

        if (!!res) {
            props.setUser({
                ...props.state,
                email: email,
                user: res,
                login: true
            })
            setError('')

        } else {
            props.setUser({
                ...props.state,
                email: null,
                user: null,
                login: false,

            })
            setError('Utilisateur non trouvé')
        }

    }

    return (

        <div >

            <div>
                <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                <InputText
                    value={email}
                    id="email"
                    type="text"
                    className="w-full mb-3"
                    onChange={
                        (e) => changeEmail(e.target.value)
                    }
                />
                {
                    !goodMail &&
                    <Message
                        className="text-xl p-mr-2"
                        severity="info"
                        text={`Veuillez renseigner un email valide`}
                    />
                }
            </div>

            {
                goodMail && !!error &&
                <Message
                    className="text-xl mt-2"
                    severity="info"
                    text={error}
                />
            }
            {
                goodMail &&
                <div>
                    <Button className="mt-2" onClick={(e) => checkUser()}>Connection</Button>
                </div>
            }

        </div>


    )

}

export default ModalLogin;