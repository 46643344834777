// Fonction pour envoyer une requete a l'API (recuperer les subscriptions du client avec l'email)

import { urlBdd } from './urlBdd'

export const updateStripeBalance = async (props) => {
    const balance = props.amount * 100
    const url = `${urlBdd}/customer/balance/stripe?&amount=${String(balance)}&cus_id=${String(props.id)}`

    const init = { method: 'POST' }
    const response = await fetch(url, init).then((response) => {
        if (!response.ok) {
            throw new Error("HTTP status " + response.status);
        }
        return response;
    })
    return response

}