
import React, { useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { Message } from 'primereact/message';

import { CopyToClipboard } from 'react-copy-to-clipboard';

const Link = (props) => {

    const [gender, setGender] = useState(null)
    const [product, setProduct] = useState("vip")
    const [link, setLink] = useState(null)
    const [copy, setCopy] = useState(false)

    const changeGender = (value) => {

        if (value !== null) {
            setGender(value)
            setCopy(false)
        } else {
            setGender(null)
            setLink(null)
            setCopy(false)
        }
    }

    const changeProduct = (value) => {

        if (value !== null) {
            setProduct(value)
            setCopy(false)
        } else {
            setProduct(null)
            setLink(null)
            setCopy(false)
        }
    }

    useEffect(() => {
        if (product && gender) {
            setLink(`https://www.paiement.alphabody.fr/${product}-${gender}?ambassadeur=${props.ambassador_id}`)
        }
    }, [link, setLink, product, gender, props.ambassador_id])

    const genderSelect = [
        { name: 'Un homme', value: 'homme' },
        { name: 'Une femme', value: 'femme' }
    ];
    const productSelect = [
        { name: 'Vip', value: 'coaching' },
        { name: 'Formation', value: 'formation' }
    ];

    return (
        <div>

            <div className="text-900">
                <div className="text-xl font-medium text-900 mb-3 mt-3">Tu veux parrainer :</div>
                <div className="sm:block md:flex">
                    <SelectButton className="m-1 flex" optionLabel="name" value={gender} options={genderSelect} onChange={(e) => changeGender(e.value)} />
                    {!!!product &&
                        < SelectButton className="m-1 flex" optionLabel="name" value={product} options={productSelect} onChange={(e) => changeProduct(e.value)} />
                    }
                </div>
            </div>

            {
                link ?
                    <div className="text-900">
                        <div className="text-3xl font-medium text-900 mb-3 mt-3">Ton lien :</div>
                        <div className="select-all overflow-hidden text-overflow-ellipsis">{link}</div>
                        <div className="mt-3 block">
                            <CopyToClipboard text={link}
                                onCopy={() => setCopy(true)}
                            >
                                <Button label="Copier le lien" icon={copy && "pi pi-check"} />
                            </CopyToClipboard>
                            {copy && <Message className="mt-2 ml-2" severity="success" text="Lien copié" />}
                        </div>

                    </div>
                    :
                    <Message icon="info" text="Choisis le type de parainnage" />

            }

        </div>

    )

}

export default Link;