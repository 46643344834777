import axios from 'axios';
import { urlBdd } from './urlBdd'

export const getCustomerWithAffiliation = async (email) => {

  //getCustomerByMail with affiliated
  const customer = await axios({
    method: 'post',
    url: `${urlBdd}/graphQl`,

    data: {
      query: `
            query getCustomerByMail (
                $email: String!){
                    
              getCustomerByMail (
                email:$email){
                            id,
                            firstname,
                            lastname,
                            email,
                            activ,
                            created_at,
                            alpha_point,
                            product_id{
                              id,
                              name
                            },
                            sale{
                              id,
                              type,
                              next_term,
                              processor_cus_id
                            }            
                            affiliated{
                                      id,
                                      firstname,
                                      lastname,
                                      email,
                                      activ,
                                      created_at,
                                      product_id{
                                        id,
                                        name
                                      },
                                      sale{
                                        id,
                                        type,
                                        next_term,
                                        processor_cus_id,
                                        detail
                                      }  
                }
                  
              }
            }`,
      variables: {
        "email": email
      }
    }

  })

  if (customer) {
    return customer.data.data.getCustomerByMail
  } else {
    return null
  }



}