
const gifts = {

    ///REMISE SUR ABBONEMENT
    abbo1: {
        title: "Remise de 50 € sur la prochaine facture",
        nbPoints: 50,
        msgConfirm: "Cette action va diminuer ta prochaine facture de 50€ et débiter ton compte de 50 alphaPoints, elle n'est valable qu'une seule fois par facture.",
        type: "reducAbbo"
    },
    abbo2: {
        title: "Remise de 100 € sur la prochaine facture",
        nbPoints: 100,
        msgConfirm: "Cette action va diminuer ta prochaine facture de 100€ et débiter ton compte de 100 alphaPoints, elle n'est valable qu'une seule fois par facture.",
        type: "reducAbbo"
    },
    abbo3: {
        title: "Remise de 150 € sur la prochaine facture",
        nbPoints: 150,
        msgConfirm: "Cette action va diminuer ta prochaine facture de 150€ et débiter ton compte de 150 alphaPoints, elle n'est valable qu'une seule fois par facture.",
        type: "reducAbbo"
    },

    //BON DE REDUCTION WOOCOMMERCE
    reducWoo1: {
        title: "Bon de réduction de 25 € sur tes compléments",
        nbPoints: 25,
        msgConfirm: "Cette action va générer un bon de 25€ sur la boutique shopAlpha et débiter ton compte de 25 alphaPoints.",
        type: "reducWoo"
    },
    reducWoo2: {
        title: "Bon de réduction de 50 € sur tes compléments",
        nbPoints: 50,
        msgConfirm: "Cette action va générer un bon de 50€ sur la boutique shopAlpha et débiter ton compte de 50 alphaPoints.",
        type: "reducWoo"
    },

}

export default gifts